import PageSEO from '../components/seo'
import PageMainContent from '../components/page-main-content'
import MainFormSection from '../components/page-sections/main-form-section/MainFormSection'
import { IStrapiCase, IStrapiIndustry, IStrapiPage, IStrapiPageLayout, IStrapiSeo, IStrapiService } from '../types/strapi'
import { graphql, PageProps } from 'gatsby'
import SectionLayout from '../layouts/section-layout/SectionLayout'
import { Typography } from '@mui/material'

export interface IPageDataResponse {
  strapiPage: IStrapiPage
}

export interface IPageContext {
  id: string
  seo: IStrapiSeo
  layout: IStrapiPageLayout
  pageTitle?: string
  cases?: Array<IStrapiCase>
  industries?: Array<IStrapiIndustry>
  services?: Array<IStrapiService>
}

type IPageProps = PageProps<IPageDataResponse, IPageContext>

const Page = ({ data, pageContext }: IPageProps) => {
  const { mainForm, sections = [] } = data.strapiPage

  return (
    <>
      {pageContext.pageTitle && (
        <SectionLayout options={sections[0].options}>
          <Typography variant='h1' color='text.primary'>{pageContext.pageTitle}</Typography>
        </SectionLayout>
      )}
      <PageMainContent sections={sections} />
      <MainFormSection data={mainForm} />
    </>
  )
}

export default Page

export const Head = ({ data, location, pageContext }: IPageProps) => {
  const { preloadImage } = data.strapiPage
  const { seo } = pageContext

  return (
    <PageSEO
      seo={seo}
      pathname={location.pathname}
      preloadImage={preloadImage}
    />
  )
}

export const query = graphql`
  query($id: String!) {
    strapiPage(id: {eq: $id}) {
      mainForm {
        ...StrapiMainFormSection
      }
      preloadImage {
        ...StrapiMedia
      }
      sections {
        ... on STRAPI__COMPONENT_SECTIONS_DEFAULT_SECTION {
          ...StrapiDefaultSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_QUOTE_SECTION {
          ...StrapiQuoteSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_CASES_SECTION {
          ...StrapiCasesSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_LIST_SECTION {
          ...StrapiListSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_ICON_LIST_SECTION {
          ...StrapiIconListSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_CAROUSEL_SECTION {
          ...StrapImagesCarouselSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_REVIEWS_CAROUSEL_SECTION {
          ...StrapReviewsCarouselSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_LICENSES_CAROUSEL_SECTION {
          ...StrapiLicensesCarouselSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_CARDS_SECTION {
          ...StrapiBasicCardsSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_TEXT_CARDS_SECTION {
          ...StrapiTextCardsSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_IMAGE_CARDS_SECTION {
          ...StrapiImageCardsSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_LICENSE_CARDS_SECTION {
          ...StrapiLicenseCardsSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_REVIEW_CARDS_SECTION {
          ...StrapiReviewCardsSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_TABS_LAYOUT {
          ...StrapiTabsLayout
        }
        ... on STRAPI__COMPONENT_SECTIONS_GRID_LAYOUT {
          ...StrapiGridLayout
        }
        ... on STRAPI__COMPONENT_SECTIONS_IMAGE_SECTION {
          ...StrapiImageSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_CASES_CAROUSEL_SECTION {
          ...StrapCasesCarouselSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_BUTTONS_SECTION {
          ...StrapiButtonsSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_CASES_SELECTION_SECTION {
          ...StrapiCasesSelectionSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_WYSIWYG_SECTION {
          ...StrapiWysiwygSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_BLOG_SECTION {
          ...StrapiBlogSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_STEPS_SECTION {
          ...StrapiStepsSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_FAQ_SECTION {
          ...StrapiFaqSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_SOCIALS_SECTION {
          ...StrapiSocialsSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_BREAD_CRUMBS_SECTION {
          ...StrapiBreadCrumbsSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_TABS_LIST_SECTION {
          ...StrapiTabsListSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_TEXT_CARDS_CAROUSEL_SECTION {
          ...StrapiTextCardsCarouselSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_ACTION_CARDS_SECTION {
          ...StrapiActionCardsSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_TABLE_SECTION {
          ...StrapiTableSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_PRICE_TAGS_SECTION {
          ...StrapiPriceTagsSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_PRICE_CARDS_SECTION {
          ...StrapiPriceCardsSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_SIMPLE_CARDS_SECTION {
          ...StrapiSimpleCardsSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_HOVERING_SECTION {
          ...StrapiHoveringSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_PRICE_LABEL_CARDS_SECTION {
          ...StrapiPriceLabelCardsSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_UNISENDER_FORM_SECTION {
          ...StrapiUnisenderFormSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_COLLAGE_SECTION {
          ...StrapiCollageSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_PRICE_OFFER_CARDS_SECTION {
          ...StrapiPriceOfferCardsSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_PROMO_SECTION {
          ...StrapiPromoSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_LICENSE_SECTION {
          ...StrapiLicenseSection
        }
      }
    }
  }
`
